import React from 'react';
import PropTypes from 'prop-types';
import { sizes, types as flashMessageTypes } from 'constants/flashMessages/flashMessages';
import language, { i18n } from 'constants/language/language';
import { getFormattedPriceValue } from 'constants/price/price';
import cartEntriesCount from 'constants/cartEntriesCount/cartEntriesCount';
import { getDifferenceToFreeDelivery } from 'constants/cart/cart';
import { noop } from 'constants/noop/noop';
import Cart from 'components/organisms/Cart/Cart';
import OrderBox from 'components/molecules/OrderBox/OrderBox';
import FlashMessage from 'components/atoms/FlashMessage/FlashMessage';
import { Col, Row } from 'components/atoms/Grid/Grid';
import Headline from 'components/atoms/Headline/Headline';
import TimeSensitiveInfoBox from 'components/atoms/TimeSensitiveInfoBox/TimeSensitiveInfoBox';
import bootstrap from 'scss/component.scss';
import styles from './CartLayout.scss';

const renderDeliveryInfo = (cart) => {
  const freeDeliveryDiff = getDifferenceToFreeDelivery(cart.surchargeThreshold?.value, cart.subTotal?.value);
  if (freeDeliveryDiff === 0) {
    return null;
  }
  return (
    <div className={styles.deliveryInfo}>{i18n('cart.deliveryInfo', [getFormattedPriceValue(freeDeliveryDiff)])}</div>
  );
};

const CartLayout = ({cart, clearUpdatedCartHistory = noop, products = {}, flashMessage = {}}) => {
  return (
    <Row className={styles.container}>
      <Col>
        <Headline tag={Headline.tags.H1} skin={Headline.skins.pagetitle}>
          {language('cart.title')}
        </Headline>
      </Col>
      <div className={bootstrap.srOnly}>
        <Headline tag={Headline.tags.H2}>{language('cart.entries')}</Headline>
      </div>
      <Col lg={8} xl={9}>
        {flashMessage.message && (
          <FlashMessage type={flashMessage.type} content={flashMessage.message} size={sizes.LARGE}/>
        )}
        {cart.voucherRemoved && (<FlashMessage type={flashMessageTypes.WARNING}
                                                 content={i18n("shoppingCart.voucherRemoved")}
                                                 size={sizes.LARGE}/>)}
        {renderDeliveryInfo(cart)}
        {cart.cartUpdated && <TimeSensitiveInfoBox isMainInfo removedEntries={cart.removedEntries}/>}
        <Cart cart={cart} products={products}/>
        <div className={styles.discounts}>{language('cart.discounts')}</div>
      </Col>
      <Col lg={4} xl={3} className={styles.orderBox}>
        <div className={styles.stickyBox}>
          <OrderBox
            totalItems={cartEntriesCount(cart.entries)}
            totalPriceNet={cart.subTotalNet}
            totalReducedTax={cart.subTotalReducedTax}
            totalFullTax={cart.subTotalFullTax}
            orderTotal={cart.subTotal}
            deliveryCost={cart.deliveryCost}
            fullTaxRate={cart.totalFullTax.rate}
            reducedTaxRate={cart.totalReducedTax.rate}
            isCart
            clearUpdatedCartHistory={clearUpdatedCartHistory}
          />
        </div>
      </Col>
    </Row>
  );
}

CartLayout.displayName = 'layouts/CartLayout';
CartLayout.propTypes = {
  cart: PropTypes.object.isRequired,
  clearUpdatedCartHistory: PropTypes.func,
  flashMessage: PropTypes.object,
  products: PropTypes.object,
};

export default CartLayout;
