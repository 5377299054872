import React from 'react';
import PropTypes from 'prop-types';
import { getPageContentNeed, getBrandContentNeed } from 'actions/cmsActions/cmsActions';
import CmsCurrentPageIdContainer from 'containers/CmsCurrentPageIdContainer/CmsCurrentPageIdContainer';
import CmsTemplate from 'components/templates/Cms/Cms';
import NextFeatureEnabled from 'routes/ProductsOverview/NextFeatureEnabled';

const conditions = [
  {
    featureName: 'nextBrandsEnabled',
    include: '/brand/',
  },
  {
    featureName: 'nextCmsEnabled',
    include: '/cms/',
    exclude: ['/cms/service/ruecknahme', '/cms/service/reprise', '/cms/servizio/ritiro'],
  },
  {
    featureName: 'nextAccountReturnsEnabled',
    include: '/cms/service/ruecknahme',
  },
  {
    featureName: 'nextAccountReturnsEnabled',
    include: '/cms/service/reprise',
  },
  {
    featureName: 'nextAccountReturnsEnabled',
    include: '/cms/servizio/ritiro',
  },
];

const CmsDynamic = ({ route = {} }) => (
  <>
    <NextFeatureEnabled conditions={conditions} />
    <CmsCurrentPageIdContainer isBrandPage={route.isBrandPage}>
      <CmsTemplate />
    </CmsCurrentPageIdContainer>
  </>
);

CmsDynamic.need = [getPageContentNeed, getBrandContentNeed];

CmsDynamic.displayName = 'routes/CmsDynamic';
CmsDynamic.propTypes = {
  route: PropTypes.object,
};

export default CmsDynamic;
