import { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory, withRouter } from 'react-router';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { getOnlineId, getUser, loginUser, updateEmailField, verifyRecaptcha } from 'actions/userActions/userActions';
import { mergeCart } from 'actions/cartActions/cartActions';
import { mergeWatchlist } from 'actions/watchlistActions/watchlistActions';
import { resetLoginNotification, showPopOverLogin } from 'actions/uiActions/uiActions';
import { scrollToElement, TOP_CONTENT_ANCHOR } from 'constants/scrollToElement/scrollToElement';
import { LoginValidationTypes } from 'constants/loginValidation/loginValidation';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { getLocale } from 'constants/language/language';
import { OPTIN, OPTIN_NOT_CONFIRMED, OPTIN_TYPE_REGISTRATION } from 'constants/routePaths/routePaths';

import { recoMapUserToSession } from 'api/RecoAPI/RecoAPI';

import getCookieStorage from 'constants/storage/cookie';
import { RECO_COOKIE } from 'constants/storage/storageKeys';

const cookie = getCookieStorage();

export const mapStateToProps = (state, ownProps) => ({
  loginValidation: state?.user?.loginValidation || true,
  email: state?.user?.fields?.email?.value || '',
  onlineId: state?.user?.onlineId || '',
  loginNotification: state?.ui?.loginNotification || null,
  apiStatus: state?.user?.apiStatus || apiStatus.success,
  recaptchaNeeded: state?.user?.recaptchaNeeded || false,
  resetCaptcha: state?.user?.resetCaptcha || false,
  // use redirectTo from router state if set, otherwise use own prop
  redirectTo: ownProps?.location?.state?.redirectTo || ownProps.redirectTo,
});

const mapDispatchToProps = {
  loginUser,
  mergeCart,
  mergeWatchlist,
  getUser,
  getOnlineId,
  resetLoginNotification,
  showPopOverLogin,
  verifyRecaptcha,
  updateEmailField,
};

export class Login extends Component {
  static propTypes = {
    redirectTo: PropTypes.string,
    loginValidation: PropTypes.oneOf(Object.values(LoginValidationTypes)),
    children: PropTypes.element.isRequired,
    loginUser: PropTypes.func,
    getUser: PropTypes.func,
    getOnlineId: PropTypes.func,
    onlineId: PropTypes.string,
    mergeCart: PropTypes.func,
    mergeWatchlist: PropTypes.func,
    resetLoginNotification: PropTypes.func,
    email: PropTypes.string,
    loginNotification: PropTypes.string,
    redirectToOnFailure: PropTypes.string,
    showPopOverLogin: PropTypes.func,
    isPopOver: PropTypes.bool,
    apiStatus: PropTypes.string,
    recaptchaNeeded: PropTypes.bool,
    resetCaptcha: PropTypes.bool,
    verifyRecaptcha: PropTypes.func,
    updateEmailField: PropTypes.func,
    location: PropTypes.object,
  };

  static defaultProps = {
    isPopOver: false,
    redirectTo: '',
    redirectToOnFailure: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      swipeErrorBox: false,
      showSpinner: false,
    };
  }

  shouldComponentUpdate = (nextProps) =>
    nextProps.apiStatus !== apiStatus.success || this.props.recaptchaNeeded !== nextProps.recaptchaNeeded;

  handleLogin = (email, password, keepLogin) => {
    this.setState({ showSpinner: true });
    this.props.loginUser(email, password, keepLogin).then(async (response) => {
      this.props.showPopOverLogin(false);
      if (response) {
        await this.props.getUser();
        this.props.getOnlineId();
        Promise.all([this.props.mergeWatchlist(), this.props.mergeCart()]).then(([, cartResp]) => {
          this.setState({ showSpinner: false });
          if (!cartResp) {
            browserHistory.push(mapPathToLocalizedUrl(getLocale()));
          } else {
            browserHistory.push({
              pathname: this.props.redirectTo,
              state: {
                // indicates that the user has been redirected to the login page and will now be forwarded
                // to the originally page
                loginRedirect: this.props?.location?.state?.redirectTo || false,
              },
            });
          }
        });
        recoMapUserToSession(cookie.getItem(RECO_COOKIE));
      } else {
        this.setState({ showSpinner: false });
        if (this.props.loginValidation === LoginValidationTypes.DoubleOptInAuthenticationError) {
          if (this.props.isPopOver) this.props.resetLoginNotification();
          browserHistory.push({
            pathname: mapPathToLocalizedUrl(getLocale(), [OPTIN, OPTIN_TYPE_REGISTRATION, OPTIN_NOT_CONFIRMED]),
            state: { email },
          });
        } else {
          // User tried to login from the popover in the header
          // and needs to be redirected to the login route because of the failed login
          if (this.props.redirectToOnFailure) {
            browserHistory.push(this.props.redirectToOnFailure);
          } else {
            // change swipeErrorBox to true to trigger swipe animation
            this.setState({ swipeErrorBox: !this.state.swipeErrorBox });

            // change shouldSwipe to false after 500ms to trigger swipe animation next time again
            const debounceSwipe = debounce(() => {
              this.setState({ swipeErrorBox: !this.state.swipeErrorBox });
            }, 500);

            debounceSwipe();
          }

          scrollToElement(TOP_CONTENT_ANCHOR);
        }
      }
    });
  };

  render() {
    return cloneElement(this.props.children, {
      handleLogin: this.handleLogin,
      loginValidation: this.props.isPopOver ? true : this.props.loginValidation,
      email: this.props.email,
      loginNotification: this.props.loginNotification,
      resetLoginNotification: this.props.resetLoginNotification,
      swipeErrorBox: this.state.swipeErrorBox,
      hideTooltip: this.props.isPopOver,
      isPopOver: this.props.isPopOver,
      apiStatus: this.props.apiStatus,
      showSpinner: this.state.showSpinner,
      recaptchaNeeded: this.props.recaptchaNeeded,
      verifyRecaptcha: this.props.verifyRecaptcha,
      resetCaptcha: this.props.resetCaptcha,
      updateEmailField: this.props.updateEmailField,
    });
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
