export const mobileProtectionOneYear = 'ZHPV';
export const mobileProtectionTwoYears = 'ZHPG';
export const mobileProtectionOneYearT4M = 'ZM12';
export const mobileProtectionTwoYearT4M = 'ZM24';
export const warantyTwoYears = 'ZGDL';

export const extendWarantyGroup = '01_garantieplus';
export const mobileProtectionGroup = '02_mobileProtection';

export const isMobileProtectionService = (serviceCode) =>
  [mobileProtectionOneYear, mobileProtectionTwoYears, mobileProtectionOneYearT4M, mobileProtectionTwoYearT4M].includes(
    serviceCode
  );

export const getMobileProtectionServices = (services = []) =>
  services.filter((service) => service.groupCode === mobileProtectionGroup);

export const getWarantyServices = (services = []) =>
  services.filter((service) => service.groupCode === extendWarantyGroup);
