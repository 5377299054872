import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import language from 'constants/language/language';
import NewsletterOptout from 'components/templates/NewsletterOptout/NewsletterOptout';
import Meta from 'containers/Meta/Meta';
import NewsletterContainer from 'containers/Newsletter/NewsletterOptout';
import NextFeatureEnabled from 'routes/ProductsOverview/NextFeatureEnabled';

const mapStateToProps = (state, ownProps) => ({
  hash: ownProps?.location?.query?.hash,
  personalized: ownProps?.location?.query?.personalized === 'true',
});

const Optout = ({ hash = '', personalized = false }) => (
  <>
    <Meta
      title={language(personalized ? 'pageTitles.newsletterOptoutPersonalized' : 'pageTitles.newsletterOptout')}
      meta={[{ name: 'robots', content: 'noindex' }]}
    />{' '}
    <NextFeatureEnabled conditions={[{ include: 'optout', featureName: 'nextNewsletterEnabled' }]} />
    <NewsletterContainer hash={hash} isPersonalized={personalized}>
      <NewsletterOptout />
    </NewsletterContainer>
  </>
);

Optout.displayName = 'routes/NewsletterOptout';
Optout.propTypes = {
  hash: PropTypes.string,
  personalized: PropTypes.bool,
};

export default connect(mapStateToProps)(Optout);
