import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import language, { getLocale } from 'constants/language/language';
import { isLoggedIn } from 'constants/user/user';
import {
  accountRegistration,
  // newsletterRegistration,
} from 'constants/routePaths/routePathCombinations';

import { newsletterFeedbackTypes } from 'constants/newsletterFeedbackTypes/newsletterFeedbackTypes';
import NewsletterFeedbackTemplate from 'components/templates/NewsletterFeedbackTemplate/NewsletterFeedbackTemplate';
import { NEWSLETTER } from 'constants/routePaths/routePaths';

export const renderMarkdownLink = (linkText, link) => `[${linkText}](${link})`;

export const getLink = (feedbackType) => {
  let link;
  if (feedbackType === newsletterFeedbackTypes.CONFIRMATION || feedbackType === newsletterFeedbackTypes.GUEST) {
    link = mapPathToLocalizedUrl(getLocale(), accountRegistration);
    //  used for re-register (after goLive)
    // } else if (feedbackType === newsletterFeedbackTypes.UNSUBSCRIBE)
    //   link = mapPathToLocalizedUrl(getLocale(), newsletterRegistration);
  }
  return link;
};

export const renderLinkIntoText = (text, feedbackType) => {
  const textParts = text.split('|');
  const link = getLink(feedbackType);
  if (textParts.length > 1 && link) {
    textParts[1] = renderMarkdownLink(textParts[1], link);
    return textParts.join('');
  }
  return text;
};

const NewsletterFeedback = ({ feedbackType, sendOptoutReasons, hash, email, loggedIn, newsletter }) => {
  if (
    feedbackType === newsletterFeedbackTypes.VERIFICATION ||
    !Object.values(newsletterFeedbackTypes).includes(feedbackType)
  ) {
    browserHistory.push(mapPathToLocalizedUrl(getLocale(), [NEWSLETTER, 'failure']));
  } else {
    const languageConfig = language(`newsletterFeedback.${feedbackType}`);
    const options = languageConfig ? { ...languageConfig } : {};

    if (loggedIn && newsletter) {
      if (feedbackType === newsletterFeedbackTypes.REGISTRATION) {
        options.text = language('newsletterFeedback.confirmation.text');
      }
    }

    if (options.text) {
      email = email.replaceAll('_', '\\_');
      email = email.replaceAll('*', '\\*');
      const text = options.text.replace('{EMAIL}', email);
      options.text = renderLinkIntoText(text, feedbackType);
    }

    if (options.text2) {
      options.text2 = renderLinkIntoText(options.text2, feedbackType);
    }

    return (
      <NewsletterFeedbackTemplate
        {...options}
        feedbackType={feedbackType}
        sendOptoutReasons={sendOptoutReasons}
        hash={hash}
        email={email}
      />
    );
  }
  return null;
};

const mapStateToProps = (state) => {
  return {
    loggedIn: isLoggedIn(state.user),
    newsletter: 'newsletter' in state.user && state.user.newsletter,
  };
};

NewsletterFeedback.propTypes = {
  feedbackType: PropTypes.oneOf(Object.values(newsletterFeedbackTypes)),
  sendOptoutReasons: PropTypes.func,
  hash: PropTypes.string,
  email: PropTypes.string,
  loggedIn: PropTypes.bool,
  newsletter: PropTypes.bool,
};

export default connect(mapStateToProps, {})(NewsletterFeedback);
