import React from 'react';
import PropTypes from 'prop-types';
import WatchlistIndication from 'containers/Indications/WatchlistIndicator';
import ProductComparisonIndication from 'containers/Indications/ProductComparisonIndicator';
import CartIndication from 'containers/Indications/Cart';
import Icon, { ICON_BASKET, ICON_COMPARE_ARROWS, ICON_FAVORITE_BORDER } from 'components/atoms/Icon/Icon';
import IconLink, { skins } from 'components/atoms/IconLink/IconLink';
import CartIndicator, { skins as indicatorSkins } from 'components/atoms/CartIndicator/CartIndicator';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import language, { getLocale } from 'constants/language/language';
import { CART, PRODUCT_COMPARISON, WATCHLIST } from 'constants/routePaths/routePaths';

import styles from './PopOverIndications.scss';

const PopOverIndications = ({ cyData }) => (
  <>
    <IconLink
      featureName="nextComparisonEnabled"
      href={mapPathToLocalizedUrl(getLocale(), [PRODUCT_COMPARISON])}
      skin={skins.POPOVER}
      {...(cyData ? { cyData: cyData[0] } : {})}
    >
      <div>
        <Icon path={ICON_COMPARE_ARROWS} />
        {language('header.productComparison')}
      </div>
      <div className={styles.badge}>
        <ProductComparisonIndication {...(cyData ? { cyData: cyData[1] } : {})} />
      </div>
    </IconLink>
    <IconLink
      featureName="nextWatchlistEnabled"
      href={mapPathToLocalizedUrl(getLocale(), [WATCHLIST])}
      skin={skins.POPOVER}
    >
      <div>
        <Icon path={ICON_FAVORITE_BORDER} />
        {language('header.shoppingList')}
      </div>
      <div className={styles.badge}>
        <WatchlistIndication />
      </div>
    </IconLink>
    <IconLink href={mapPathToLocalizedUrl(getLocale(), [CART])} skin={skins.POPOVER}>
      <div>
        <Icon path={ICON_BASKET} />
        {language('navigation.cart')}
      </div>
      <div className={styles.badge}>
        <CartIndication>
          <CartIndicator skin={indicatorSkins.MENU} />
        </CartIndication>
      </div>
    </IconLink>
  </>
);

PopOverIndications.displayName = 'molecules/PopOverIndications';

PopOverIndications.propTypes = {
  cyData: PropTypes.array,
};

export default PopOverIndications;
