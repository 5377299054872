import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import language from 'constants/language/language';
import { apiStatus } from 'constants/apiStatus/apiStatus';
import { cypressAttributes } from 'constants/cypress/cypress';
import dataTracking from 'constants/trackingAttributes/dataTracking';

export const mapStateToProps = (state) => ({
  buttonDisabled: (state?.orders?.apiStatus || apiStatus.success) === apiStatus.request,
});

const CheckoutSubmit = ({
  handlePlaceOrder,
  buttonDisabled,
  couponInvalid,
  cartInvalid,
  storeInvalid,
  billingAddressInvalid,
}) => {
  const handleRefreshClick = () => {
    window.location.reload();
  };

  const isCartInvalid = couponInvalid || cartInvalid || storeInvalid || billingAddressInvalid;

  return (
    <>
      <ButtonLink
        color={ButtonLink.colors.red}
        size={ButtonLink.sizes.md}
        onClick={isCartInvalid ? handleRefreshClick : handlePlaceOrder}
        stretched
        disabled={!isCartInvalid && buttonDisabled}
        data-cy={cypressAttributes.checkout.btnOrderNow}
        data-tracking={dataTracking({
          TYPE: 'form:button',
          FUNCTION: 'go-to',
          ATTRIBUTE_1: 'primary',
          ATTRIBUTE_2: language('orderBox.orderNow'),
        })}
      >
        <span>{isCartInvalid ? language('orderBox.refreashOrder') : language('orderBox.orderNow')}</span>
      </ButtonLink>
    </>
  );
};

CheckoutSubmit.displayName = 'containers/CheckoutSubmit';

CheckoutSubmit.propTypes = {
  handlePlaceOrder: PropTypes.func,
  buttonDisabled: PropTypes.bool,
  couponInvalid: PropTypes.bool,
  cartInvalid: PropTypes.bool,
  storeInvalid: PropTypes.bool,
  billingAddressInvalid: PropTypes.bool,
};

export default connect(mapStateToProps)(CheckoutSubmit);
