import React from 'react';
import PropTypes from 'prop-types';
import language from 'constants/language/language';
import { noop } from 'constants/noop/noop';
import CheckoutSubmit from 'containers/CheckoutSubmit/CheckoutSubmit';
import Icon, { ICON_VERIFIED_USER_OUTLINED } from 'components/atoms/Icon/Icon';
import styles from './CheckoutButton.scss';

const CheckoutButton = ({
  handlePlaceOrder = noop,
  couponInvalid,
  cartInvalid,
  storeInvalid,
  billingAddressInvalid,
}) => (
  <>
    <CheckoutSubmit
      handlePlaceOrder={handlePlaceOrder}
      couponInvalid={couponInvalid}
      cartInvalid={cartInvalid}
      storeInvalid={storeInvalid}
      billingAddressInvalid={billingAddressInvalid}
    />
    <div className={styles.securedText}>
      <small className={styles.text}>{language('orderBox.securedText')}</small>
      <Icon path={ICON_VERIFIED_USER_OUTLINED} />
    </div>
  </>
);

CheckoutButton.displayName = 'molecules/CheckoutButton';
CheckoutButton.propTypes = {
  handlePlaceOrder: PropTypes.func,
  couponInvalid: PropTypes.bool,
  cartInvalid: PropTypes.bool,
  storeInvalid: PropTypes.bool,
  billingAddressInvalid: PropTypes.bool,
};

export default CheckoutButton;
