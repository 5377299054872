// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { updateEntry as updateEntryAction } from 'actions/cartActions/cartActions';
import AddServices from 'components/molecules/AddServices/AddServices';

import type { Cart, CartEntry } from 'reducers/cart/cart';

type IProps = {
  entryID: number,
  cartEntry: CartEntry,
  availableProductServices: Array<string>,
  updateEntry: (number, ?string, number, Array<string>) => void,
};

const mapStateToProps = ({ cart }: { cart: Cart }, ownProps: IProps) => ({
  cartEntry: (cart.entries[ownProps.entryID]: CartEntry),
});

const mapDispatchToProps = {
  updateEntry: updateEntryAction,
};

const CartServicesInline = ({ entryID, cartEntry, availableProductServices = [], updateEntry }: IProps) => {
  // we can init serviceItemCodes from props since CartServices is always re-mounted on modal open/close
  const [serviceItemCodes, setServiceItemCodes] = useState(cartEntry?.serviceItemCodes ?? []);

  const updateServices = (serviceCode: string, incompatibleServiceItemCodes: Array<string> = []) => {
    let newCodes = serviceItemCodes;

    if (serviceItemCodes.includes(serviceCode)) {
      newCodes = serviceItemCodes.filter((code) => code !== serviceCode);
      setServiceItemCodes(newCodes);
    } else {
      const compatibleServices = serviceItemCodes.filter((code) => !incompatibleServiceItemCodes.includes(code));
      newCodes = [...new Set([...compatibleServices, serviceCode])];
      setServiceItemCodes(newCodes);
    }

    updateEntry(entryID, cartEntry.productCode, cartEntry.quantity, newCodes, cartEntry.serviceItemCodes);
  };

  return (
    <>
      <AddServices
        services={availableProductServices}
        updateServices={updateServices}
        serviceItemCodes={serviceItemCodes}
      />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CartServicesInline);
