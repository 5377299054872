import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sendOptoutReasons as sendOptoutReasonsAction } from 'actions/newsletterActions/newsletterActions';
import language from 'constants/language/language';
import Meta from 'containers/Meta/Meta';
import NewsletterFeedback from 'components/organisms/NewsletterFeedback/NewsletterFeedback';
import { newsletterFeedbackTypes } from 'constants/newsletterFeedbackTypes/newsletterFeedbackTypes';
import NextFeatureEnabled from 'routes/ProductsOverview/NextFeatureEnabled';

export const mapStateToProps = (state, ownProps) => ({
  feedbackType: ownProps.params.feedbackType,
  hash: ownProps.location?.state?.hash || '',
  email: state?.user?.newsletterEmail || '',
});

const mapDispatchToProps = {
  sendOptoutReasons: sendOptoutReasonsAction,
};

const Newsletter = ({ feedbackType, sendOptoutReasons = () => {}, hash = '', email = '' }) => (
  <>
    <Meta
      title={language(`newsletterFeedback.${feedbackType}.title`)}
      meta={[{ name: 'robots', content: 'noindex' }]}
    />
    <NextFeatureEnabled
      conditions={[
        {
          featureName: 'nextNewsletterEnabled',
          include: 'registration',
        },
      ]}
    />

    <NewsletterFeedback feedbackType={feedbackType} sendOptoutReasons={sendOptoutReasons} hash={hash} email={email} />
  </>
);

Newsletter.displayName = 'routes/Newsletter';
Newsletter.propTypes = {
  feedbackType: PropTypes.oneOf(Object.values(newsletterFeedbackTypes)),
  hash: PropTypes.string,
  sendOptoutReasons: PropTypes.func,
  email: PropTypes.string,
  personalized: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
