import React from 'react';
import PropTypes from 'prop-types';
import DrilldownAccordion from 'components/atoms/DrilldownAccordion/DrilldownAccordion';
import MobileNavigationItem from 'components/atoms/MobileNavigationItem/MobileNavigationItem';
import language, { getLocale } from 'constants/language/language';

import { NAVIGATION_SUB } from 'constants/trackingAttributes/trackingAttributes';

import { types } from 'containers/FooterColumnCmsContainer/FooterColumnCmsContainer';

import { provideLinks } from 'constants/navigation/navigation';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { BLOG, CMS } from 'constants/routePaths/routePaths';

const BlogSection = ({ title, pages, type, isResetToStart, pos }) => {
  const links = provideLinks(pages, type, getLocale());
  const POSITION_OFFSET = 2;

  return (
    <DrilldownAccordion title={language('navigation.blog')} pos={pos} isResetToStart={isResetToStart}>
      <MobileNavigationItem
        featureName="nextCmsEnabled"
        id="blog"
        href={mapPathToLocalizedUrl(getLocale(), [CMS, BLOG], false)}
        tracking={{
          type: NAVIGATION_SUB,
          position: 1,
          title: language('navigation.blog'),
          parentTitle: language('navigation.blog'),
        }}
      >
        {language('navigation.blog')}
      </MobileNavigationItem>
      {links.map((link, i) => (
        <MobileNavigationItem
          key={link.text}
          href={link.url}
          tracking={{
            type: NAVIGATION_SUB,
            position: i + POSITION_OFFSET,
            title: link.text,
            parentTitle: title,
          }}
        >
          {link.text}
        </MobileNavigationItem>
      ))}
    </DrilldownAccordion>
  );
};

export default BlogSection;

BlogSection.propTypes = {
  isResetToStart: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.oneOf(Object.values(types)),
  pages: PropTypes.object,
  pos: PropTypes.number,
};
