export const newsletterFeedbackTypes = {
  REGISTRATION: 'registration',
  SUBSCRIBE: 'subscribe',
  GUEST: 'guest',
  CONFIRMATION: 'confirmation',
  FAILURE: 'failure',
  UNSUBSCRIBE: 'unsubscribe',
  UNSUBSCRIBE_PERSONALIZED: 'unsubscribe_personalized',
  VERIFICATION: 'verification',
  USED: 'used',
  PENDING: 'pending',
  ACCEPTED: 'accepted',
};

export const verificationResults = {
  VALID: 'VALID',
  USED: 'USED',
  EXPIRED: 'EXPIRED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
  UNSUBSCRIBED: 'UNSUBSCRIBED',
};
