import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import language from 'constants/language/language';
import IndividualAdsOptoutTemplate from 'components/templates/IndividualAdsOptout/IndividualAdsOptout';
import Meta from 'containers/Meta/Meta';
import NewsletterContainer from 'containers/Newsletter/NewsletterOptout';
import NextFeatureEnabled from 'routes/ProductsOverview/NextFeatureEnabled';

const mapStateToProps = (state, ownProps) => ({
  hash: ownProps.location?.query?.hash,
  isPersonalized: ownProps.location?.query?.personalized,
});

const IndividualAdsOptout = ({ hash = '', isPersonalized = false }) => (
  <>
    <Meta title={language('pageTitles.newsletterOptout')} meta={[{ name: 'robots', content: 'noindex' }]} />
    <NextFeatureEnabled conditions={[{ include: 'optout', featureName: 'nextNewsletterEnabled' }]} />

    <NewsletterContainer individualizedAds={true} hash={hash} isPersonalized={isPersonalized}>
      <IndividualAdsOptoutTemplate />
    </NewsletterContainer>
  </>
);

IndividualAdsOptout.displayName = 'routes/IndividualAdsOptout';
IndividualAdsOptout.propTypes = {
  hash: PropTypes.string,
  isPersonalized: PropTypes.bool,
};

export default connect(mapStateToProps)(IndividualAdsOptout);
