import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { verifyNewsletterToken } from 'actions/newsletterActions/newsletterActions';
import { getLocale } from 'constants/language/language';
import {
  newsletterFeedbackTypes,
  verificationResults,
} from 'constants/newsletterFeedbackTypes/newsletterFeedbackTypes';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import { NEWSLETTER } from 'constants/routePaths/routePaths';
import { apiStatus, invalidApiStatus } from 'constants/apiStatus/apiStatus';
import NextFeatureEnabled from 'routes/ProductsOverview/NextFeatureEnabled';

export const mapStateToProps = (state, ownProps) => ({
  token: ownProps?.params?.token || '',
  newsletterApiStatus: state?.user?.newsletterApiStatus || apiStatus.success,
  isRegisteredCustomer: state?.user?.isRegisteredCustomer ?? false,
  verificationResult: state?.user?.verificationResult || verificationResults.REJECTED,
});

const mapDispatchToProps = {
  verifyNewsletterToken,
};

export const getParam = (verificationResult, isRegistered) => {
  switch (verificationResult) {
    case verificationResults.VALID:
    case verificationResults.ACCEPTED:
      return isRegistered ? newsletterFeedbackTypes.CONFIRMATION : newsletterFeedbackTypes.GUEST;
    case verificationResults.USED:
      return newsletterFeedbackTypes.USED;
    default:
      return newsletterFeedbackTypes.FAILURE;
  }
};

class NewsletterVerification extends React.Component {
  static propTypes = {
    token: PropTypes.string,
    verifyNewsletterToken: PropTypes.func,
    newsletterApiStatus: PropTypes.oneOf(Object.values(apiStatus)),
    isRegisteredCustomer: PropTypes.bool,
    verificationResult: PropTypes.string,
  };

  UNSAFE_componentWillMount() {
    if (!this.props.token) {
      this.redirectTo(newsletterFeedbackTypes.FAILURE);
    }
    const { token } = this.props;
    const encoded = encodeURIComponent(token);
    this.props.verifyNewsletterToken(encoded).then(() => {
      const param = getParam(this.props.verificationResult, this.props.isRegisteredCustomer);
      this.redirectTo(param);
    });
  }

  redirectTo = (param) => {
    if (this.props.newsletterApiStatus === apiStatus.success) {
      browserHistory.push(mapPathToLocalizedUrl(getLocale(), [NEWSLETTER, param]));
    } else if (invalidApiStatus.indexOf(this.props.newsletterApiStatus) > -1) {
      browserHistory.push(mapPathToLocalizedUrl(getLocale(), [NEWSLETTER, newsletterFeedbackTypes.FAILURE]));
    }
  };

  render() {
    const { token } = this.props;

    return (
      <NextFeatureEnabled
        conditions={[
          {
            featureName: 'nextNewsletterEnabled',
            include: encodeURIComponent(token),
          },
        ]}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterVerification);
