import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import imageSizes from 'constants/imageSizes/imageSizes';
import language, { getLocale } from 'constants/language/language';
import { PRODUCT_COMPARISON } from 'constants/routePaths/routePaths';
import getUrlForProduct from 'constants/getUrlForProduct/getUrlForProduct';
import dataTracking from 'constants/trackingAttributes/dataTracking';
import { mapPathToLocalizedUrl } from 'constants/urlMapping/urlMapping';
import RemoveFromCartLink from 'containers/RemoveFromCart/RemoveFromCartLink';
import Image from 'components/atoms/Image/Image';
import IconLink from 'components/atoms/IconLink/IconLink';
import Headline from 'components/atoms/Headline/Headline';
import ButtonLink from 'components/atoms/ButtonLink/ButtonLink';
import ShortenedHeadline from 'components/atoms/ShortenedHeadline/ShortenedHeadline';
import Icon, { ICON_ADD_CIRCLE_OUTLINE } from 'components/atoms/Icon/Icon';
import bootstrap from 'scss/component.scss';
import { HeaderOverviewConfig } from './ProductComparisonHeader.config';
import styles from './ProductComparisonOverviewHeader.scss';
import { cypressAttributes } from 'constants/cypress/cypress';
import { productClick } from 'actions/trackingActions/trackingActions';
import { NextFeatureLink } from 'components/atoms/NextFeatureLink';

const mapDispatchToProps = {
  productClick: productClick,
};

class ProductComparisonOverviewHeader extends Component {
  static displayName = 'molecules/ProductComparisonOverviewHeader';

  static propTypes = {
    products: PropTypes.array,
    categoryCode: PropTypes.string,
    removeFromComparison: PropTypes.func,
    showError: PropTypes.bool,
    getComparisonList: PropTypes.func,
    productCodes: PropTypes.array,
    hideComparison: PropTypes.func,
    productClick: PropTypes.func,
  };

  static defaultProps = {
    products: [],
    categoryCode: '',
    removeFromComparison: () => {},
    getComparisonList: () => {},
    showError: false,
    productCodes: [],
    hideComparison: () => {},
    productClick: () => {},
  };

  componentDidMount = () => {
    this.props.getComparisonList(this.props.productCodes.filter(Boolean));
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.productCodes) !== JSON.stringify(this.props.productCodes)) {
      this.props.getComparisonList(nextProps.productCodes);
    }
  }

  getImages = (imageData) => imageData?.sizes || [];

  removeProduct = (code, categoryCode) => {
    this.props.removeFromComparison(code, categoryCode);
  };

  hideComparison = () => {
    this.props.hideComparison();
  };

  render() {
    const { products, categoryCode, showError } = this.props;
    const productsArray = products;

    // Fills the array up with empty entries, up to 3
    while (productsArray.length < 3) {
      productsArray.push('');
    }

    const productsLength = products.filter(Boolean).length;

    return (
      <div>
        <div className={cx(bootstrap.row, styles.overviewWrapper, bootstrap.hiddenMdDown)}>
          <div className={cx(bootstrap.colMd3, styles.outerWrapper)}>
            <Headline margin={HeaderOverviewConfig.headlineMargin}>
              {language('productComparison.overview.header')}
            </Headline>
          </div>
          <div className={cx(bootstrap.colMd6, styles.outerWrapper, styles.products)}>
            {productsArray.map((product, index) => (
              <div className={styles.productOuterWrapper} key={index}>
                {!product.code ? (
                  <div className={styles.emptyProduct}>
                    <div className={cx(styles.productImageWrapper, styles.icon)}>
                      <Icon path={ICON_ADD_CIRCLE_OUTLINE} size={Icon.sizes.xxl} />
                    </div>
                  </div>
                ) : (
                  <div className={styles.productInnerWrapper}>
                    <NextFeatureLink
                      feature="nextPdpEnabled"
                      className={cx(styles.product, { [styles.hasError]: showError })}
                      to={getUrlForProduct(categoryCode, product.name, product.code, getLocale())}
                      key={index}
                      onClick={() => productClick(product, '')}
                    >
                      <div className={styles.productImageWrapper}>
                        {product.customImageData.length > 0 && (
                          <Image
                            alt={product.name}
                            images={this.getImages(product.customImageData[0])}
                            size={imageSizes.productDetail.size}
                            sizes={imageSizes.productDetail.sizes}
                            responsive={imageSizes.productDetail.responsive}
                            code={product.customImageData[0].code}
                          />
                        )}
                      </div>
                      <ShortenedHeadline maxLength={30} margin={Headline.margins.NONE} text={product.name} />
                    </NextFeatureLink>
                    <div className={styles.removeLink}>
                      <IconLink onClick={() => this.removeProduct(product.code, categoryCode)}>
                        <RemoveFromCartLink
                          trackingData={dataTracking({
                            TYPE: 'compare',
                            ATTRIBUTE_1: 'remove',
                            SKU: product.code,
                          })}
                          cyData={cypressAttributes.comparisonList.removeFromComparisonListLink}
                        />
                      </IconLink>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className={cx(bootstrap.colMd3, styles.outerWrapper)}>
            {productsLength >= 2 ? (
              <ButtonLink
                featureName="nextComparisonEnabled"
                size={ButtonLink.sizes.sm}
                variety={ButtonLink.varieties.flat}
                href={mapPathToLocalizedUrl(getLocale(), [PRODUCT_COMPARISON])}
                stretched
                cyData={cypressAttributes.product.compareProductsButton}
              >
                {language('productComparison.overview.compareNow')}
              </ButtonLink>
            ) : (
              <span>{language('productComparison.overview.addMoreFull')}</span>
            )}
          </div>
        </div>
        {productsLength > 0 && (
          <div className={bootstrap.hiddenLgUp}>
            <div className={styles.mobileHeaderBox}>
              <div className={styles.infoText}>
                <span>
                  <b>{`${productsLength} ${language('productComparison.articles')} `}</b>
                  {`${language('productComparison.in')} `}
                  <NextFeatureLink
                    feature="nextComparisonEnabled"
                    to={mapPathToLocalizedUrl(getLocale(), [PRODUCT_COMPARISON])}
                  >
                    {`${language('productComparison.header')}`}
                  </NextFeatureLink>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(undefined, mapDispatchToProps)(ProductComparisonOverviewHeader);
