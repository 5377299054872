import React from 'react';
import Meta from 'containers/Meta/Meta';
import WatchlistContainer from 'containers/WatchlistContainer/WatchlistContainer';
import WatchlistTemplate from 'components/templates/Watchlist/Watchlist';
import NextFeatureEnabled from 'routes/ProductsOverview/NextFeatureEnabled';

const Watchlist = () => (
  <>
    <Meta meta={[{ name: 'robots', content: 'noindex' }]} />
    <NextFeatureEnabled conditions={[{ featureName: 'nextWatchlistEnabled', include: 'watchlist' }]} />
    <WatchlistContainer>
      <WatchlistTemplate />
    </WatchlistContainer>
  </>
);

export default Watchlist;
